import { render, staticRenderFns } from "./ScoreVideo.vue?vue&type=template&id=142b550e&scoped=true&"
import script from "./ScoreVideo.vue?vue&type=script&lang=js&"
export * from "./ScoreVideo.vue?vue&type=script&lang=js&"
import style0 from "./ScoreVideo.vue?vue&type=style&index=0&id=142b550e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "142b550e",
  null
  
)

export default component.exports